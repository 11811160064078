import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `Online Corporate Training Programs`,
    content:
      "Our Corporate Training Programs and UI UX Workshop educate and empower your employees with design skills to help you grow your business.",
  };

  const commonContent6 = {
    title:
      "Skills We Offer With Our <span class='h1-span'>UI UX Design Training</span>",
    para: "We offer a comprehensive and customizable UI UX Workshop and Online Corporate Training to impart practical knowledge and industry insights to your team to help you build a design-centric culture.",
    img: ["what-service-image-empowerbusiness.webp"],
    imageTitle: 'Corporate Training',
    imageAlt: 'ux ui design training',
    content: [
      {
        title: "User-Centric Design Approach",
        text: "With our UX design workshop, we equip your team with a complete understanding of user behavior to craft effective digital products. ",
      },
      {
        title: "Interactive Design Principles",
        text: "Our UI design workshop enables your team to create engaging interfaces through a deep understanding of design principles. ",
      },
      {
        title: "Responsive Design Techniques",
        text: "We help you dive into advanced design techniques to create highly functional and visually appealing products.",
      },
      {
        title: "Visual Communication Skills",
        text: "We improve your team’s ability to understand and convey complex information through UI UX design training.",
      },
      {
        title: "IA Understanding",
        text: "We educate your team to architect and organize website components for improved usability and user experience.",
      },
      {
        title: "Design Thinking Methodology",
        text: "Our UI UX workshop equips your team with the design methodology to create, innovate & solve design challenges.",
      },
      {
        title: "Color Theory and Typography",
        text: "We provide a technical understanding of color theory and typography to improve the overall website experience.",
      },
      {
        title: "Analytical Skills",
        text: "We improve your team’s technical skills derived from user feedback to continuously improve design.",
      }
    ],
  };
  const commonContent = {
    title:
      "Who needs Corporate Training Workshops?",
    para: "Our online corporate training is beneficial for organizations seeking to adopt a design-driven culture by crafting customized digital products and improving product offerings.",
    img: ["who-needs-empowerbusiness.webp"],
    imageTitle: 'UI UX Workshops and Corporate Training Program',
    imageAlt: 'web design training online',
    content: [
      {
        title: "Tech Companies",
        text: "Companies that want to equip their teams with the latest knowledge on user interface, product design, and industry trends.",
      },
      {
        title: "Consumer Companies",
        text: "Companies who want to improve navigation and offer seamless customer experience to drive more conversions and foster loyalty.",
      },
      {
        title: "Healthcare Companies",
        text: "Companies looking to create a user-friendly interface for seamless and easy navigation, resulting in customer satisfaction.",
      },
      {
        title: "Ed-Tech Companies",
        text: "Companies looking to create an engaging and intuitive interface for offering improved learning and exceptional customer experience.",
      },
    ],
  };

  const commonContent2 = {
    title: "Benefits of Corporate Training Programs",
    para: "Online corporate training workshops inculcate a design-centric mindset in your team to craft excellent user experience and drive sustainable business growth.",
    img: ["what-are-benefits-empowerbusiness.webp"],
    imageTitle: 'Corporate Training Benefits',
    imageAlt: 'ui ux workshop',
    content: [
      {
        title: "Increased Productivity",
        text: "Corporate training programs equip your team with collaborative techniques, design processes, etc., that reduce iterations and increase productivity.",
      },
      {
        title: "Increased Conversion Rates",
        text: "Corporate training workshops suggest ways to optimize the user journey, streamline workflow, etc., resulting in increased conversions.",
      },
      {
        title: "Long-term Investment",
        text: "UI UX Workshop is a long-term investment for your business as it minimises the need to redesign and post-launch fixes.",
      },
      {
        title: "Enhanced User Experience",
        text: "A UX design workshop instills user-centric design principles, testing methodologies, effective user flow, and other techniques to offer an enhanced and seamless user experience. ",
      },
    ],
  };
  const commonContent3 = {
    title:
      "Choose Octet for <span class='h1-span'>UI UX Workshop</span>",
    linkText: ``,
    para: `We offer the best UI UX workshops and corporate training programs by equipping your team with the skills required to excel in the digital era and cultivating a design-centric culture within your business.`,
    img: ["why-opt-for-empowerbusiness.webp"],
    imageTitle: 'Corporate Training Company',
    imageAlt: 'ux design workshops',
    content: [
      {
        title: "Hands-on Approach",
        text: "Through our interactive activities, market trends, and live industrial examples, we inculcate real-world scenarios to empower your team.",
      },
      {
        title: "Practical tools & techniques",
        text: "We offer practical training sessions on various tools and techniques that are design-specific and related to your industry.",
      },
      {
        title: "Industrial Insights",
        text: "We address industry-specific needs, challenges, opportunities, etc., to ensure that your team stays up-to-date with industrial trends.",
      },
      {
        title: "Post-program Support",
        text: "We ensure that you succeed and offer support by providing guidance, resources, and assistance post your corporate training program.",
      },
    ],
  };
  const commonContent4 = {
    title:
      "Our Online Corporate <span class='h1-span'>Training Method</span>",
    para: "We follow a practical approach to deliver high-quality UI UX design workshops. Through our corporate training programs, we provide industrial insights to upgrade design knowledge and cultivate strong relationships within your team.",
    img: ["how-we-conduct-empowerbusiness.webp"],
    imageTitle: 'UI UX Workshops and Corporate Training Process',
    imageAlt: 'design thinking workshop',
    content: [
      {
        title: "1. Building Consensus",
        text: "We find common ground between our design team and the client's product team.",
      },
      {
        title: "3. Impart Learning",
        text: "We share our design knowledge and insights from our experience on important topics.",
      },
      {
        title: "2. Stimulate Ideation",
        text: "We explore new ideas or potential solutions to specific problems and build a better product.",
      },
      {
        title: "4. Cultivate Relationships",
        text: "We focus on developing strong relationships between our team members and clients' counterparts.",
      },
    ],
  };
  const commonContent5 = {
    title: "Positive Outcomes of UX <span class='h1-span'>Investing in UI UX Workshop</span>",
    para: "UI UX workshops and corporate training programs are a long-term investment for your business as they offer practical knowledge, industrial insights, and hands-on experience with various tools and techniques that unlock your team's potential to deliver competitive designs.",
    content: [
      {
        title: "84% Prefers Overall Experience",
        text: "Users prefer websites that enhance the overall experience apart from their products and services.",
      },
      {
        title: "72% Shared customer experience",
        text: "It is believed that a satisfied customer will tell six other people about their good experience on your websites.",
      },
      {
        title: `66% Willingness to pay`,
        text: "Companies that invest in UI UX design training to understand user challenges attract customers who are willing to pay more for a better experience.",
      },
    ],
    desc: "*These data are from public sources, we do not claim to owe these stats.",
  };
  const cta = {
    title: "Upgrade Your Team With UI UX Design Training!",
  };
  const cta2 = {
    title: "Book Corporate Training <span>Workshops for Your Team!</span>",
  };
  const cta3 = {
    title: "Transform Your Team With <span>UX Design Workshop!</span>",
  };
  const cta4 = {
    title: "Unlock Growth with <span class='h1-span'>Online Corporate Training!</span>",
  };
  const cta5 = {
    title: "Invest in Design Excellence <span class='h1-span'>with UI UX Workshop!</span>",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: "KYS",
      desc: "Supplier GST Performance Platform",
      Industry: "SaaS",
      link: '/project/ui-ux-design-kys/',
      WhatWeDid: [
        {
          title: 'User Research',
          link: '/user-research/'
        },
        {
          title: 'UI UX Designing',
          link: '/ui-ux-designing/'
        },
        {
          title: 'Angular Development',
          link: '/angularjs-development/'
        }
      ],
      images: ["kys.webp", "kys-2.webp"],
      firstAlt: "",
      firstTitle: "Dashboard",
      secondAlt: "",
      secondTitle: "Suppliers Solutions",
    },
    {
      title: "CloudForceHr",
      desc: "A SaaS Based HRMS Software",
      Industry: "Enterprise",
      link: '/project/ui-ux-design-cloudforcehr/',
      WhatWeDid: [
        {
          title: 'Digital Transformation',
          link: '/digital-transformation/'
        },
        {
          title: 'UI UX Designing',
          link: '/ui-ux-designing/'
        },
        {
          title: 'Angular Development',
          link: '/angularjs-development/'
        },
      ],
      images: ["cloudforce.webp", "cloudforce-2.webp"],
      firstAlt: "training for web design",
      firstTitle: "Configuration Screen",
      secondAlt: "ui ux design training",
      secondTitle: "Bulk Action on Employees Data",
    },
  ];

  const Questions = [
    "Does your course need any prior knowledge to attend the sessions?",
    "Can you customise your training programs for specific industrial needs?",
    "Do you conduct remote Design Workshops and Corporate training?",
    "What type of UI UX Workshop do you conduct?",
    "What is the duration of your UI/UX workshops and training programs?",
    "How much do you charge for Corporate Training courses?",
    "How can we enrol in your UI UX Workshop and Corporate Training Programs?",
  ];
  const clientsTitle =
    "Brands who chose our <span class='h1-span'>UX Design Workshops</span>";
  const faqDes = `We understand you must have more questions about our UI UX Workshops and Corporate Training Programs. In this section, we answer certain questions asked by our clients; however, if you still have any doubts, please  <a href="/contact-us/" title="Contact Us">contact us</a>.`;

  const faqData = [
    {
      para: [
        `No, you don’t need any prior knowledge or experience to attend our UI UX design training workshops. We have designed our program keeping all the levels of expertise in mind and starting from fundamental to advanced topics.`,
        `We ensure everyone grasps the clear design concept and offers practical examples for an effective learning experience.`,
      ],
    },
    {
      para: [
        `Yes, we can customize our corporate training programs to meet your specific business needs. We understand your unique challenges and aim to address the requirements through tailor-made courses that include case studies, live examples, etc.`,
        `Our trainers leverage their expertise working with different industries to address your pain points. We ensure you get maximum value from our UI UX workshops and online corporate training.`,
      ],
    },
    {
      para: [
        `Yes, we conduct remote UI UX design workshops and corporate training programs. We utilize several online platforms and collaborative tools to interact with your team and offer engaging lessons.`,
        `Through our flexible approach, we aim to offer high-quality training and valuable insights.`,
      ],
    },
    {
      para: [
        `We offer workshops with a hands-on approach to apply best practices and industry insights to inculcate deep design understanding within your team. The types of workshops that we conduct are- `,
      ],
      list: [
        `<span><h4 class="inline">Design Thinking Workshops</h4></span> - We enable clients to solve complex problems through a design thinking approach.`,
        `<span><h4 class="inline">5-Day Design Sprints</h4></span> - We prefer to conduct design sprints, which allow us to go from a rough idea to building a prototype and testing it in just five days.`,
        `<span><h4 class="inline">Discovery Workshops</h4></span> -  We explore the problem, ideate different solutions with stakeholders, define goals, and set priorities.`,
        `<span><h4 class="inline">Audit Workshops</h4></span> - We identify the inconsistencies in the product concerning design and prioritize by their level of severity.`,
      ],
    },
    {
      para: [
        `The duration of the UI UX workshop and corporate training courses depends on the specific workshop you choose. `,
        `However, we offer a flexible learning program, allowing your team to understand every concept required for your business.`,
      ],
    },
    {
      para: [
        `The cost of the workshops or training program depends on the course you choose, its duration, the number of participants involved, etc. `,
        `Our goal is to deliver high-quality training that increases your business value. Hence, we ensure that our pricing represents the quality of the courses we offer. `,
        `We would request you <a href="/contact-us/" target="_blank" rel="noreferrer">contact us</a> to get a detailed quotation on the UI UX workshop and corporate training programs.`,
      ],
    },
    {
      para: [
        `To register for our UI UX workshop and corporate training courses, visit our website and fill out the <a href="/contact-us/" target="_blank" rel="noreferrer">contact us</a> form. We will then contact you and guide you throughout the process.`,
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "1. Does your course need any prior knowledge to attend the sessions?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No, you don’t need any prior knowledge or experience to attend our UI UX design training workshops. We have designed our program keeping all the levels of expertise in mind and starting from fundamental to advanced topics. 
            
            We ensure everyone grasps the clear design concept and offers practical examples for an effective learning experience."
                }
              },{
                "@type": "Question",
                "name": "2. Can you customize your Corporate training programs for specific industrial needs?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we can customize our corporate training programs to meet your specific business needs. We understand your unique challenges and aim to address the requirements through tailor-made courses that include case studies, live examples, etc. 
            
            Our trainers leverage their expertise working with different industries to address your pain points. We ensure you get maximum value from our UI UX workshops and online corporate training."
                }
              },{
                "@type": "Question",
                "name": "3. Do you conduct remote corporate training and design workshops?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we conduct remote UI UX design workshops and corporate training programs. We utilize several online platforms and collaborative tools to interact with your team and offer engaging lessons. 
            
            Through our flexible approach, we aim to offer high-quality training and valuable insights."
                }
              },{
                "@type": "Question",
                "name": "4. What type of UI UX Workshop do you conduct?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We offer workshops with a hands-on approach to apply best practices and industry insights to inculcate deep design understanding within your team. The types of workshops that we conduct are- 
            Design Thinking Workshops
            We enable clients to solve complex problems through a design thinking approach.
            5-Day Design Sprints
            We prefer to conduct design sprints, which allow us to go from a rough idea to building a prototype and testing it in just five days.
            Discovery Workshops
            We explore the problem, ideate different solutions with stakeholders, define goals, and set priorities.
            Audit Workshops
            We identify the inconsistencies in the product concerning design and prioritize by their level of severity."
                }
              },{
                "@type": "Question",
                "name": "5. What is the duration of your UI UX workshops and corporate training programs?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The duration of the UI UX workshop and corporate training courses depends on the specific workshop you choose. 
            
            However, we offer a flexible learning program, allowing your team to understand every concept required for your business."
                }
              },{
                "@type": "Question",
                "name": "6. How much do you charge for corporate training courses?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of the workshops or training program depends on the course you choose, its duration, the number of participants involved, etc. 
            
            Our goal is to deliver high-quality training that increases your business value. Hence, we ensure that our pricing represents the quality of the courses we offer. 
            
            We would request you contact us to get a detailed quotation on the UI UX workshop and corporate training programs."
                }
              },{
                "@type": "Question",
                "name": "7. How can we enroll in your online corporate training programs?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "To register for our UI UX workshop and corporate training courses, visit our website and fill out the Contact Us form. We will then contact you and guide you throughout the process."
                }
              }]
            }                                            
          `}
        </script>
      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode="grey" data={cta} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta2} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode="grey" data={cta3} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className="container">
              <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36]">
                Case Study: Corporate <br />
                Training Programs
              </h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title={"Success Stories: Our <span clas='h1-span'>UI UX Design Training</span>"} />
          <Cta data={cta4} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
          <Cta mode="grey" data={cta5} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="Corporate Training Programs | UI UX Design Training" description="Boost your team's skills with our corporate training programs and UI UX workshop. Our expert-led sessions enhance productivity and design expertise." />
)